.footer{
    margin-right:$rightNav-width;
    @include breakpoint(large down) { 
        margin-right:0;
    }
    padding:1rem 0;
    background:$black;
    
    .links{
        ul{
            margin:0;
            list-style: none;
            
            @include breakpoint(small down) { 
                margin-bottom:2rem;
            }
            li{
                list-style: none;
                a{
                    font-size: rem-calc(12);
                    color:$white;
                    display: block;
                    padding:0.2rem 0;
                    border-bottom: 1px solid $medium-gray;                    
                    transition: color 0.2s ease-in-out;

                    &:hover{
                        color:$primary-color;
                    }
                }
            }
        }
    }
    .disclaimer{
        h2{
            color:$white;
            font-size: rem-calc(18);
        }
        p{
            color:$light-gray;
            font-size: rem-calc(11);
            line-height: 1.75;
        }
    }
}