.right-nav.nano {
    
    position: fixed;
    z-index: 6;
    //top: $header-height + $sub-header-height + $sub-header-margin-top;
    top:0;
    padding-top:0.75rem;
    right:0;
    width:$rightNav-width;
    background:$medium-gray;
    border-left:1px solid #38393c;
    text-shadow: 0 1px $black;
    
    @include breakpoint(large down) { 
        display: none;
    }
    
    .dimmed &{
        display: none;
    }
    
    a.more-link{
        display: block;
        position: absolute;
        top:1.5rem;
        right:1rem;
        font-size:rem-calc(10);
        color:$light-gray;
        
        &:hover{
            color:$white;
        }
    }
    
    .panel{
        background: none;
        padding:0.75rem 1.25rem 0.25rem 1rem;
        border-bottom:1px solid #38393c;
        margin:0;
        
        h1{
            font-size: rem-calc(15);
            margin:0.5rem 0 0 0;
            border:none;
            position: relative;
        }
    }
    
    a{
        color:$white;
        transition: all 0.2s ease-in-out;
        &:hover{
            color:$primary-color;
        }
    }
    
    .user-avatar{
        .avatar-container{
            margin:1rem 0;
        }
    }
    
    
    .favorites{
        
        $favorites-game-img-height:45px;
        padding-bottom:1rem;

        .favorites-game-list{
            width:100%;
            height:$favorites-game-img-height;
            max-height: $favorites-game-img-height;
            //overflow: hidden;
            
            .owl-stage-outer{
            height:$favorites-game-img-height;
            max-height: $favorites-game-img-height;
            overflow: hidden;
            }
            
            .owl-nav{
                width: 100%;
                display: block;
                
                .owl-prev, .owl-next{
                    position: absolute;
                    display: block;
                    top: 12px;
                    text-shadow: none;
                    color:rgba(255,255,255,0.25);
                    transition: color 0.2s ease-in-out;
                    &:hover{
                        color:$white;
                    }
                }
                .owl-prev{
                    left: -10px;
                }
                .owl-next{
                    right: -10px;
                }
            }
        }

            .game-image{

                position: relative;
                height: $favorites-game-img-height;

                img{
                    width:48px;
                    height: $favorites-game-img-height;
                    border-radius: $panel-radius;
                }
            }
    }
    
    .latest-avatar{
        position: relative;
        &.panel{
            padding-bottom: 0.25rem;
        }
        
        h1{
            margin:0;
        }
        
        .avatar-preview{
        }
        .avatar-info{
            
        }
        
        #indexAvatarPreview{
            float:left;
            width:70px;
            height:71px;
            margin-top:-14px;
            margin-left:-26px;
            position: relative;
            .indexAvatarBase{
                position: absolute;
                z-index: 1;
            }
            .indexAvatarItemBody{
                position: absolute;
                z-index: 2;
            }
        }
        h3{
            color:$white;
            font-size:rem-calc(11);
            margin:0.5rem 0 0;
            display: block;
        }
        p{
            color:$light-gray;
            font-size:rem-calc(10);
            display: inline-block;
            margin:0;
            display: block;
        }
        .gender{
            position: absolute;
            right:12px;
            top:4px;
            .fa{
                display: inline-block;
                margin:0.75rem 0rem 0 0.1rem;
                color:$primary-color;
                font-size: 0.9rem;
                &:first-of-type{
                    margin-left:0;
                }
                &:last-of-type{
                    margin-right:0;
                }
                &.disabled{
                    color:rgba(255,255,255,0.25);
                }
            }
        }
        .button{
            text-transform: uppercase;
            color:$dark-gray;
            font-weight: 900;
            margin:0.5rem 0 0;
        }
    }   

    .latest-activity{
        .button-group{
            .button{
                font-size:rem-calc(11);
                background:none;
                margin:0;
                padding:0.25rem 0.25rem;
                text-transform: uppercase;
                font-weight: 700;
                &:first-of-type{
                    padding-left:0;
                }
                &:last-of-type{
                    padding-right:0;
                }
            }
        }
        
        
        ul.latest-activity-list{
                margin:0 -1rem 0 0; 
                margin-left:2.5rem;
                padding:0;
            
                li{
                    padding:0.5rem 0.5rem 0.5rem 0;
                    border-bottom:1px solid #38393c;
                    list-style: none;

                    &:last-child{
                        border-bottom: none;
                    }
                    &:first-child{
                    }

                    .fa-li{
                        top: 50%;
                        margin-top: -12px;
                        position: absolute;
                        left: -40px;
                        color:$primary-color;
                        
                        &.star{
                            color: #ffb500;
                        }
                        
                        .fa{
                            text-shadow: none;
                            &.fa-stack-1x{
                                font-size: 0.9rem;
                                top: -3px;
                            }
                            &.fa-stack-2x{
                                font-size:1.5rem;
                            }
                            &:last-child{
                                color:$medium-gray;
                            }
                        }
                    }

                    .activity-title{
                        font-size:rem-calc(11);
                        margin:0;
                        color:$light-gray;
                        line-height: 1.25;
                        strong{
                            font-size:rem-calc(11);
                            color:$white;
                        }
                    }
                    .activity-time{
                        font-size:rem-calc(10);
                        margin:0.125rem 0 0 0;
                        color:$light-gray;
                        line-height: 1.5;
                    }
                }
            }
        
        
    }
    
    .latest-posts{
        
        $post-list-img-height:45px;

        ul.post-list{
            margin:0;
            li{
                display: block;
                list-style: none;
                margin:0 -1rem;
                padding:0.5rem 0.5rem 0.5rem 1rem;

                &:first-child{
                }
                &:not(:last-child){
                    border-bottom:1px solid rgba(255, 255, 255, 0.05);
                }
                &:last-child{
                    padding-bottom: 0.5rem;
                }

                /*img{
                    height:$post-list-img-height;
                    max-width: 40px;
                    display:inline-block;
                    float: left;
                    margin-right:1rem;
                }*/
                
                .avatar-container{
                    top: 8px;
                    zoom: 0.5;
                    -moz-transform: scale(0.5);
                    float: left;
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    max-width: 75px;
                }

                .latest-post-title{
                    color:$white;
                    font-size:rem-calc(11);
                    line-height: 1.25;
                    font-weight: 700;
                    margin:0 0 0.15rem;
                    display: block;
                }
                .latest-post-author{
                    display: block;
                    color:$light-gray;
                    font-size:rem-calc(10.5);
                    line-height: 1.25;
                    font-weight: 500;
                    transition: color 0.2s ease-in-out;
                    margin:0;
                    a{

                        transition: color 0.2s ease-in-out;
                    }
                }

                .activity-time{
                    font-size:rem-calc(10);
                    margin:0;
                    color:$light-gray;
                    line-height: 2;
                    display: block;
                }

            }
        }
    }
    
    .monthly-leaders{
        
        .monthly-leader-container{
            margin:0 -1rem;
        }
        table{
            background:none;
            width:100%;
            border:none;
            margin:0;
            thead, tbody{
                background:none;
                border:none;
                td{
                    &:first-child{
                        padding-left:1rem;
                    }
                    &:last-child{
                        padding-right:1rem;
                        padding-bottom: 0.5rem;
                    }
                }
            }
            thead{
                td{
                    font-size: rem-calc(10);
                    text-transform: uppercase;
                    color:$light-gray;
                    padding:0 0 0.5rem 0;
                }
            }
            tbody{
                tr{
                    background: none;
                    &:first-child{
                        //border-top:1px solid rgba(255, 255, 255, 0.05);
                    }
                    &:not(:last-child){
                        border-bottom:1px solid rgba(255, 255, 255, 0.05);
                    }
                    &:last-child{
                        padding-bottom: 0;
                    }
                }
                td{
                    padding:0.25rem 0;
                    font-size:rem-calc(11);
                    
                    .icon{
                        margin-right: 0.25rem;
                        font-size: 0.85rem;
                        top: 1px;
                        position: relative;
                    }
                    a{
                        font-weight: 600;
                    }
                    strong{
                        color:$white;
                    }
                    .EXP{
                        color:$light-gray;
                        font-size:rem-calc(9);
                    }
                    &:last-child{
                        text-align: right;
                    }
                    
                    img{
                        height:14px;
                        margin-right: 0.25rem;
                    }
                    
                    .level-icon{
                        margin-right: 0.25rem;
                        width: 1.25rem;
                        height: 1.25rem;
                        left: 0;
                        top:0;
                        
                        
                        @for $i from 1 through 9 {
                          &.level-#{$i} {
                            color:#75a900;
                          }
                        }
                        @for $i from 10 through 19 {
                          &.level-#{$i} {
                            color:#0091ff;
                          }
                        }
                        @for $i from 20 through 99 {
                          &.level-#{$i} {
                            color:#ca8f00;
                          }
                        }
                        &.level-99{
                            color:red;
                        }
                        
                        .fa-stack-2x{
                            font-size:1.25rem;
                        }
                        span{
                            font-family: $body-font-family;
                            font-weight: 900;
                            padding: 0;
                            color: white;
                            font-size: 0.6rem;
                            z-index: 2;
                            display: block;
                            line-height: 2;
                            height: 100%;
                            text-align: center;
                            width: 100%;
                            text-shadow: none;
                            position: relative;
                        }
                    }
                    
                }
            }
        }
        
    }
    
    .latest-walkthroughs{
        
        $walkthrough-list-height:62px;
        $walkthrough-list-info-height:40px;
        
        h1{
            padding:0;
        }

        ul{
            margin:0;
            li{
                display: block;
                list-style: none;
                margin:0 -1rem;
                padding:0.75rem 1rem;
                height:$walkthrough-list-height;

                &:first-child{
                    //border-top:1px solid rgba(255, 255, 255, 0.05);
                }
                &:not(:last-child){
                    border-bottom:1px solid rgba(255, 255, 255, 0.05);
                }
                &:last-child{
                    padding-bottom: 0;
                }

                img{
                    height:$walkthrough-list-info-height;
                    max-width: 55px;
                    display:inline-block;
                    float: left;
                    margin-right:1rem;
                }

                .latest-walkthrough-title{
                    color:$white;
                    font-size:rem-calc(11);
                    line-height: 1.1;
                    font-weight: 700;
                    margin:0rem 0 0.15rem;
                    display: block;
                }
                .latest-walkthrough-author{
                    display: block;
                    color:$light-gray;
                    font-size:rem-calc(10.5);
                    line-height: 1.25;
                    font-weight: 500;
                    transition: color 0.2s ease-in-out;
                    margin:0;
                    a{

                        transition: color 0.2s ease-in-out;
                    }
                }

            }
        }
        
    }
}