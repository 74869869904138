.header, .sub-header{
    background:$black;
    //background:rgba(0,0,0,0.6);
    position: relative;
    padding-right:$rightNav-width;
    @include breakpoint(large down) { 
        padding-right:0;
    }
}

.header{
    position: absolute;
    height:$header-height;
    width: 100%;
    top:0;
    z-index: 5;
    @include breakpoint(small down) { 
        height:$header-height-small;
    }
    
    .title-bar{
        background:$dark-gray;
    }
    .header-login, .header-menu{
        height:$header-height;
        line-height: $header-height;
        @include breakpoint(small down) { 
            height:$header-height-small;
            line-height: $header-height-small;
        }
        text-align: right;
    }
    .header-menu{
        float:left; 
        .menu-icon{  
            width: 14px;
            transition: all 0.2s ease-in-out;
            &:after{
                box-shadow: 0 5px 0 #fefefe, 0 10px 0 #fefefe;
            }
            &:hover{
                &:after{
                    box-shadow: 0 5px 0 $primary-color, 0 10px 0 $primary-color;
                    background:$primary-color;
                }
            }
        }
    }
   .header-login{
        float:right;
        //padding-right:$global-padding;
       a, button{
           color:$white;
           font-size: rem-calc(13);
           font-weight: 700;
           letter-spacing: 0.025rem;
            transition: color 0.2s ease-in-out;
            &:hover{
                color:$primary-color;
            }
       }
    }
    .header-close-window{
        position: absolute;
        left: 50%;
        height: 56px;
        max-width: 200px;
        margin-left: 160px;
        top: 26px;
        a, button{
            color:white;
            font-size: 1.15rem;
            font-weight: 700;
            transition: color 0.2s ease-in-out;
            &:hover{
                color:$primary-color;
            }
        }
    }
    .logo{
        /*position: absolute;
        left: 50%;
        height: 56px;
        max-width: 200px;
        margin-left: -$rightNav-width;*/
        position: absolute;
        left: 50%;
        top: 8px;
        //margin-left: -$rightNav-width;
        margin-left:-177px;
        img{
            height:62px;
        }
        
        @include breakpoint(small down) { 
            height: 30px;
            width: 168px;
            margin-left:-77px;
            img{
                height:34px;
            }
        }
        
        @include breakpoint(large down) { 
            margin-left:-$rightNav-width / 2;
        }
        @include breakpoint(small down) { 
            margin-left:-44px;
        }
        
        
        .bloc-logo {
            position: relative;
            width: 200px;
            height: 36px;
            float: left;
            @include breakpoint(small down) { 
                height: 30px;
                width: 168px;
            }
        }

        .bloc-logo canvas,
        .bloc-logo .logo-mask {
            display: block;
            width: 200px;
            height: 36px;
            @include breakpoint(small down) { 
                height: 30px;
                width: 168px;
            }
        }

        .bloc-logo .logo-mask {
            position: absolute;
            height: 38px;
            margin: -1px 0 0;
            top: 0;
            left: 0;
            background-size: 200px;
            background-image: url("../img/logo-mask.png");
            text-indent: -9999px;
        
            @include breakpoint(small down) { 
                height: 32px;
                background-size: 168px;
            }
        }

        @media (-webkit-min-device-pixel-ratio: 1.5),
        (min-resolution: 144dpi) {
            .bloc-logo .logo-mask {
                background-image: url("../img/logo-mask@2x.png")
            }
        }

        
        h1{
            display: inline-block;
            
            span{
                display: inline-block;
                z-index: 1;
                font-weight: 400;
                position: relative;
            }
            .beta{
                font-size: 0.5rem;
                color: #22aeff;
                font-weight: 900;
                position: absolute;
                top: 8px;
                margin-left: 0.25rem;
                
                @include breakpoint(small down) { 
                    top: -8px;
                    right:-25px;
                    font-size: 0.35rem;
                }
            }
        }
        .arrow-up {
            width: 0;
            height: 0;
            border-left: 2px solid transparent;
            border-right: 2px solid transparent;
            border-bottom: 5px solid #22aeff;
            position: absolute;
            top: 37px;
            right: 65px;
            z-index: 0;
            @include breakpoint(small down) {
                top: 22px;
                right: 46px;
                border-left-width: 2px;
                border-right-width: 2px;
                border-bottom-width: 5px;
            }
        }
    }
    h1{
        color:$white;
        font-size:rem-calc(28);
        line-height: $header-height;
        margin:0;
        text-align: center;
        text-transform: uppercase;
        font-weight: 100;
        @include breakpoint(small down) {
            font-size:rem-calc(20);
            line-height: $header-height-small;
        }
        strong{
            font-weight: 900;
        }
    }
    
    .loader-5{
        float: right;
        top: 30px;
        left: inherit;
    }
    
}
.sub-header{
    position: absolute;
    top:$sub-header-margin-top + $header-height;
    height:$sub-header-height;
    width:100%;
    ul.sub-header-menu{
        display: inline-block;
        margin-top:0;
        margin-bottom:0;
        float:left;
        margin:0;
        li{
            list-style:none;
            display: inline-block;
            line-height: $sub-header-height;
            a{
                display: block;
                color:$light-gray;
                font-weight: 700;
                    margin:0 2.0rem 0 0;
                font-size:rem-calc(12);
                text-transform: uppercase;
                transition: color 0.2s ease-in-out;
                &:hover{
                    color:$white;
                }
                
                
                
                @include breakpoint(14440px down) {
                    margin:0 1.75rem 0 0;
                font-size:rem-calc(13);
                }
                @include breakpoint(1440px down) {
                    margin:0 1.75rem 0 0;
                font-size:rem-calc(13);
                }
                @include breakpoint(1320px down) {
                    margin:0 1.75rem 0 0;
                font-size:rem-calc(12.5);
                }
                @include breakpoint(1280px down) {
                    margin:0 1.5rem 0 0;
                font-size:rem-calc(12);
                }
                @include breakpoint(1198px down) {
                    margin:0 1.75rem 0 0;
                font-size:rem-calc(13);
                }
                @include breakpoint(1090px down) {
                    margin:0 1.5rem 0 0;
                font-size:rem-calc(12);
                }
                @include breakpoint(1000px down) {
                    margin:0 1.25rem 0 0;
                font-size:rem-calc(11);
                }
                @include breakpoint(920px down) {
                    margin:0 0.75rem 0 0;
                font-size:rem-calc(10.5);
                }
                @include breakpoint(830px down) {
                    display: none;
                }
                /*@include breakpoint(1200px down) { 
                    margin:0 1.75rem 0 0;
                    font-size:0.8rem;
                }
                @include breakpoint(1140px down) { 
                    margin:0 2.0rem 0 0;
                font-size:rem-calc(12);
                }
                @include breakpoint(1056px down) { 
                    margin:0 1.5rem 0 0;
                font-size:rem-calc(12);
                }
                @include breakpoint(880px) { 
                    margin:0 0.5rem 0 0;
                    font-size:rem-calc(10);
                }
                @include breakpoint(880px) { 
                    margin:0 0.5rem 0 0;
                    font-size:rem-calc(11);
                }
                @include breakpoint(996px) { 
                    margin:0 1.0rem 0 0;
                font-size:rem-calc(12);
                }
                @include breakpoint(830px) { 
                   display: none;
                }*/

            }
            &:last-child a{
                margin-right:0;
            }
            &:first-child a{
                margin-left:0;
            }
        }
    }
    
    ul.sub-header-buttons{
        display: inline-block;
        float:right;
        margin:0;
        li{
            list-style:none;
            display: inline-block;
            line-height: $sub-header-height;
            margin:0;
            a{
                display: block;
                color:$light-gray;
                font-weight: 900;
                font-size:rem-calc(14);
                text-transform: uppercase;
                padding:0 0.15rem;
                position: relative;
                top: -1px;
                transition: color 0.2s ease-in-out;
                
                
                &.has-notification{
                    color:$primary-color;
                }
                &:last-child a{
                    padding-right:0;
                }
                &:first-child a{
                    padding-left:0;
                }
                
                &:hover{
                    color:$white;
                }
                span{
                    color:$white;
                    font-size:rem-calc(8);
                    display: block;
                    background:red;
                    border-radius: 999px;
                    height:10px;
                    width:10px;
                    line-height: 10px;
                    text-align: center;
                    position: absolute;
                    right:0px;
                    top:14px;
                }
            }
        }
    }
}