$footer-button-height:45px;

.panel{
    background:$dark-gray;
    //padding:rem-calc(20) rem-calc(26);
    padding:rem-calc(20) 0;
    position: relative;
    text-shadow: 0 1px $black;
    
    h1{
        font-size:rem-calc(18);
        margin-bottom:0.85rem;
        width:100%;
        padding:0 0 0.5rem 0;
        border-bottom:1px solid $medium-gray;
        
        
        @include breakpoint(small down) { 
            border-bottom: none;
            border-top:1px solid $medium-gray;
            padding:0.5rem 0 0 0;
        }
    }
    
    &:not(:first-of-type){
        margin-top:$panel-spacing;
    }
    &:last-of-type{
        margin-bottom:$panel-spacing;
    }
    
    a.top-right-link{
        color:$medium-gray;
        text-transform: uppercase;
        font-size: 0.6rem;
        &:hover{
            color:$white;
        }
    }
    
    &.has-footer-button{
        padding-bottom: $footer-button-height;
    }
    
    .footer-button{
        text-align: center;
        height:$footer-button-height;
        width: 100%;
        position: absolute;
        bottom:0;
        left:0;
        margin:0;
        a{
            line-height: $footer-button-height;
            display: block;
            height:$footer-button-height;
            width:100%;
            background-color:#252529;
            color:$medium-gray;
            font-size: 0.7rem;
            border-top:1px solid darken($dark-gray, 3%);
            transition: all 0.2s ease-in-out;
            
            
            .fa{
                margin-right:0.75rem;
                color:$white;
                transition: all 0.2s ease-in-out;
            }
            
            &:hover{
                //background-color: red;
                color:$white;
                .fa{
                    color:$primary-color;
                }
            }
        }
    }
}