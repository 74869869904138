



@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}
.flickerAnimation {
    -webkit-animation: flickerAnimation 1200ms infinite;
    -moz-animation: flickerAnimation 1200ms infinite;
    -o-animation: flickerAnimation 1200ms infinite;
    animation: flickerAnimation 1200ms infinite;
}    

.loader2 {
    position: relative;
    display: block;
    left: 50%;
    top: 50%;
    width: 18px;
    margin-left: -9px;
    margin-top: 0;
    margin-bottom:9px;
    height: 18px;
    border: 2px solid #3C84FA;
    border-radius: 50%;
    animation: spin 0.75s infinite linear;
}
.loader2::before,
.loader2::after {
  left: -2px;
  top: -2px;
  display: none;
  position: absolute;
  content: '';
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
}


/*
 * LOADER 5
 */
.loader-5,
.loader-5::before {
  display: block;
  border-color: transparent;
  border-top-color: $primary-color;
}
.loader-5::before {
  animation: spin 1.5s infinite ease;
}




@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    }
}
