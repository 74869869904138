$panel-spacing: 0.7rem;
$header-height:rem-calc(80);
$header-height-small:rem-calc(50);
$sub-header-height:rem-calc(28);
$sub-header-margin-top:rem-calc(2);
$rightNav-width:220px;
$panel-radius:6px;

@import 'header';
@import 'panels';
@import 'index';
@import 'rightnav';
@import 'footer';
@import 'loader';
@import '../components/owl-carousel';
@import '../components/nanoscroller';

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

h1, h2, h3, h4, h5{
    font-weight: 700;
}

h1{
    font-weight: 700;
}

.site{
    position: absolute;
    width:100%;
    height:100%;
    top:$header-height + $sub-header-height + $panel-spacing;
    
    @include breakpoint(small down) { 
        top:$header-height-small + $panel-spacing;
    }
}

.banner-ad{
    display: block;
    width:728px;
    height:90px;
    background:$white;
    margin: 0 0 1rem 0;
}
.square-ad{
    display: block;
    width:300px;
    height:234px;
    background:$white;
    margin: 0;
}

.ITkGNeQshzKt {
    display: none;
    padding: 20px 10px;
    height:100%;
    width:100%;
    background: #D30000;
    text-align: center;
    font-weight: bold;
    color: #fff;
    border-radius: 5px;
}

.dimmer{
    -webkit-transition:opacity 200ms ease-in-out;
    -moz-transition:opacity 200ms ease-in-out;
    transition:opacity 200ms ease-in-out;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    height: 0;
    width: 0;
    background-color: rgba(14,14,14,0.95);
    display: none;
    z-index: 0;
}
.dimmed .dimmer{
    height: 100%;
    width: 100%;
    display: block;
    z-index: 4;
}

.log-in{
    z-index: 5;
    position: absolute;
    width:100%;
    height:100%;
    top:$header-height + $panel-spacing;  
    padding-right:$rightNav-width;
    @include breakpoint(large down) { 
        padding-right:0;
    }  
    @include breakpoint(small down) { 
        top:$header-height-small + $panel-spacing;
    }
    h1{
        font-size: 1.5rem;
        margin: 0 0 1rem 0;
    }
    .row{
        max-width:600px;
    }
}

                        
.game-rating{
    position: absolute;
    right: 0.5rem;
    top: 8px;
    font-size:0.55rem;
    font-weight:700;
    color:$white;
    .fa{
        font-size:0.65rem;
        color:$primary-color;
        margin-right:0.1rem;
    }
}


.nano > .nano-pane{
    background:rgba(255, 255, 255, 0.5);
}