
.square4d{
    position: relative;
    display: block;
    width:336px;
    height:280px;
    //background:white;
    margin: 0 0 0 0;
    float: right;
}
.indexLeaderboard4d{
    max-width: 1200px;
    max-height: 100px;
    //background: white;
    text-align: center;
    margin: 2.5rem auto 0;
}

.indexAvatarItemBody{
    max-height: 92px;
    height: 92px;
    position: relative;
    
    .indexAvatarItem{
        position: absolute;
    }
    
    .indexAvatarSkin{
        z-index: 1;
    }
    .indexAvatarHair{
        z-index: 10;
    }
    .indexAvatarEyes{
        z-index: 8;
    }
    .indexAvatarEyebrows{
        z-index: 9;
    }
    .indexAvatarNose{
        z-index: 7;
    }
    .indexAvatarMouth{
        z-index: 5;
    }
    .indexAvatarTops{
        z-index: 6;
    }
    .indexAvatarBottoms{
        z-index: 3;
    }
    .indexAvatarShoes{
        z-index: 2;
    }
    .indexAvatarAccHead{
        z-index: 13;
    }
    .indexAvatarAccBody{
        z-index: 11;
    }
    .indexAvatarAccArms{
        z-index: 12;
    }
    .indexAvatarAccLegs{
        z-index: 4;
    }
}


$featured-game-height:380px;

.featured-games{
    margin:25px $rightNav-width 25px 0;
    @include breakpoint(large down) { 
        margin-right:0;
    }
    @include breakpoint(small down) { 
        margin:-0.7rem 0 0;
        .columns{
            padding:0;
        }
    }
    .featured-BG{
        position: absolute;
        -webkit-filter:blur(20px);
        filter:blur(20px);
        top:-($header-height + $sub-header-height + $panel-spacing);
        right:0;
        bottom: 0;
        left:0;
        height: 100%;
        max-height: 1000px;
        width: 100%;
        background-position: 50% 0;
        background-repeat: no-repeat;
        background-size: cover;
        margin: -1px 0 0 -1px;
        opacity: 0.5;
        z-index: -1;
    }
    
    img{
        width:100%;
    }
    
    .featured-game-container{
        max-height:$featured-game-height;
        overflow: hidden;
        height:100%;
    }
    
    .featured-game{
        max-height:$featured-game-height;
        overflow: hidden;
                //background:$white;
                position: relative;
                
                .featured-image{
                    img{
                        //width:100%;
                        max-height: $featured-game-height;
                        border-radius: $panel-radius;
                        z-index: 1;
                        
                        @include breakpoint(small down) { 
                            border-radius: 0;
                        }
                    }
                    @include breakpoint(medium) {
                       &:after {
                            display: block;
                            position: relative;
                            background-image: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, 0.95) 80%);
                            margin-top: -250px;
                            height: 250px;
                            width: 100%;
                            content: '';
                           z-index: 2;
                            pointer-events: none;
                            border-radius: $panel-radius;
                        }
                    }
                }
                
                .featured-info{
                    pointer-events: none;
                    //background:$white;
                    color: $light-gray;
                    padding: 1.25rem 1.25rem;
                    line-height: 20px;
                    position: absolute;
                    bottom: 0;
                       z-index: 3;
                    text-shadow: 1px 1px $black;
                    width:100%;
                    h2{
                        font-size: 1.1rem;
                        font-weight:700;
                        margin:0 0 0.5rem 0;
                        line-height:inherit;
                        color:$white;
                    }
                    
                    .featured-rating{
                        position: absolute;
                        right: 1.25rem;
                        top: 22px;
                        font-size: 1rem;
                        color: $white;
                    }
                    .fa{
                        font-size:0.85rem;
                        color:$primary-color;
                    }
                    span{
                        font-size:0.75rem;
                        font-weight:700;
                    }
                    p{
                        font-size:0.8rem;
                        line-height:1.35;
                        color:$white;
                        margin-bottom:0.5rem;
                    }
                    
                    .featured-stats{
                        font-size:0.65rem;
                        margin:0;
                        ul{
                            margin:0;
                            li{
                                display: inline-block;
                                list-style: none;
                                margin-right:1rem;
                                color:$white;
                                &:last-child{
                                    margin-right:0;
                                }
                            }
                        }
                        strong{
                            color:$primary-color;
                            font-weight: 700;
                        }
                    }
                }
            }
    

}


.page-content {
    
    margin-right:$rightNav-width;
    @include breakpoint(large down) { 
        margin-right:0;
    }
    background:$dark-gray;
    .main-content {
        &.columns{
            //padding-right: inherit;
            @include breakpoint(medium up) { 
                padding-right: $panel-spacing;
            }
        }
    }
    
    
    
    
    
    $scrolling-game-img-height:130px;
    $scrolling-game-img-height-small:80px;
    $scrolling-game-info-height:130px;
    
    $scrolling-top-game-img-height:80px;
    $scrolling-top-game-info-height:100px;
    
    .scrolling-game-list{
        
        width:100%;
        height:$scrolling-game-img-height + $scrolling-game-info-height;
        overflow: hidden;
        margin-bottom: 1rem;
        
        @include breakpoint(small down) { 
            height:inherit;
            margin-bottom: 0;
        }
        
        .column{

            &:not(:first-child){
                padding-left: rem-calc(2);
            }
            &:last-child{
            }
        }

        .game-image{
            
            position: relative;
            height: $scrolling-game-img-height;
            @include breakpoint(small down) { 
                height: $scrolling-game-img-height-small;
            }
            
            img{
                height: $scrolling-game-img-height;
                border-radius: $panel-radius $panel-radius 0 0;
                max-width: 216px;
                width: 100%;
                
                @include breakpoint(small down) { 
                    border-radius: 6px;
                    height: $scrolling-game-img-height-small;
                }
            }
        }
        .game-info{
            position: relative;
            display: block;
            height:$scrolling-game-info-height;
            width:100%;
                max-width: 216px;
            background:$medium-gray;
            padding:0.6rem 0.8rem;
            border-radius: 0 0 $panel-radius $panel-radius;
            
            @include breakpoint(small down) { 
                height:inherit;
                background:none;
                padding:0.25rem 0;
            }
                
            h3{
                font-size:rem-calc(15);
                font-weight:700;
                margin:0 0 0.25rem 0;
                line-height:inherit;
                @include breakpoint(small down) { 
                    font-size:rem-calc(11);
                    margin:0;
                }
            }
            p{
                font-size:rem-calc(12);
                color:$light-gray;
                margin:0;
                height:60px;
                overflow: hidden;
            }
            .game-rating{
                display: block;
                position: relative;
                z-index: 1;
                width: 100%;
                /* height: 20px; */
                padding: 5px 0 0 0;
                left: 0;
                top: 0;
                text-align: left;
                @include breakpoint(small down) { 
                    margin:0;
                    padding:0;
                    color:$light-gray;
                }
            }
        }
    }
    
    
    .latest-games{
        &.panel{
            @include breakpoint(small down) { 
                padding-top:0;
            }
        }
        
        h3{
            height:22px;
            overflow: hidden;
        }
    }
    
    .top-games{
        
        .panel{
            padding-top:0;
            @include breakpoint(small down) { 
                margin:0;
            }
        }
        @include breakpoint(medium up) { 
            .column{
                padding:0 1rem;
            }
        }
        
        .scrolling-game-list{
            height:inherit;
        }
        
        .game-image{
            height: $scrolling-top-game-img-height;
            img{
                width:100%;
                height: $scrolling-top-game-img-height;

                @include breakpoint(small down) { 
                    height: $scrolling-game-img-height-small;
                }
            }
        }
        .game-info{
            height:$scrolling-top-game-info-height;
            @include breakpoint(small down) { 
                height: inherit;
            }
            h3{
                font-size:rem-calc(11);
                height:1rem;
                overflow: hidden;
            }
            p{
                font-size:rem-calc(9.5);
                height:44px;
            }
        }
    }
}

.multiplayer-games{
    
    &.panel{
        margin-top:0;
    }
    .item{
        float:left;
        
        margin:0 5px;
        &:first-child{
            margin-left:0;
        }
        &:last-child{
            margin-right:0;
        }
        
        img{
            width: 216px;
            cursor: pointer;
        }
    }
}